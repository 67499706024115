import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../style/userlist.css";
import axios from 'axios';
import config from "../config/config";
import Pagination from '@mui/material/Pagination'; // Import Pagination component from Material UI

const Userlist = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const PerPage = 15;

    useEffect(() => {
        axios.get(`${config.apiBaseUrl}/admin/userlist`)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the user list!', error);
            });
    }, []);

    const indexOfLastUser = currentPage * PerPage;
    const indexOfFirstUser = indexOfLastUser - PerPage;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(users.length / PerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div className="Teams">
                <h1 className="usertitle">User List</h1>
                <div className="common-overall-container">
        <div className="table-responsive leads_height_table">
          <table className="table table-bordered">
            <thead className="table-th">
              <tr>
                            <th className='text-center'>S.no</th>
                            <th>Username</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-responsive">
                        {currentUsers.map((user, index) => (
                            <tr key={user.id}>
                                {/* Adjust the index to show correct user numbers across pages */}
                                <td className='text-center'>{indexOfFirstUser + index + 1}</td>
                                <td>{user.username}</td>
                                <td>{user.mobile_no}</td>
                                <td>{user.email}</td>
                                <td>{user.created_at}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>              
            </div>
            </div>
       
            <div className="d-flex justify-content-end mt-3">
                    <Pagination
                        count={totalPages} // Total pages
                        page={currentPage} // Current page
                        onChange={handlePageChange} // Handle page change
                        color="primary" // Set color to primary
                        siblingCount={1} // Number of page numbers to show on either side
                        boundaryCount={1} // Number of pages to show on the start and end
                    />
                </div>
        </div>
    );
};

export default Userlist;
