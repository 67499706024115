import "../style/SubscriptionPlans.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config/config";
import Pagination from '@mui/material/Pagination'; 
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationModal from "./ConfirmationModal"; 
function Paymentdetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const emailsPerPage = 15;
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isSending, setIsSending] = useState(false);


  
    const subscription = async () => {
    axios
      .get(`${config.apiBaseUrl}/admin/Paymenttransaction`)
      .then((response) => {
        setPaymentHistory(response.data);     
      })
      .catch((error) => {
        console.log("Failed to fetch payment history", error);
      });
    }
      useEffect(() => {
        subscription();
      }, []);
      

  const indexOfLastEmail = currentPage * emailsPerPage;
  const indexOfFirstEmail = indexOfLastEmail - emailsPerPage;
  const currentEmails = paymentHistory.slice(indexOfFirstEmail, indexOfLastEmail);

  const totalPages = Math.ceil(paymentHistory.length / emailsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const calscerificate = async (user_id) => {
    try {
      setIsSending(true);
      await axios.post(`${config.apiBaseUrl}/admin/calscertificate`, {
        user_id,
      });
      toast.success("Certificate issued successfully");
      setShowModal(false);
      subscription()
    } catch (error) {
      toast.error("Failed to issue certificate");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="Teams">
      <ToastContainer />
      <h6 className="inboxhead">Subscription List</h6>
      <div className="common-overall-container">
        <div className="table-responsive leads_height_table">
          <table className="table table-bordered">
            <thead className="table-th">
              <tr>
                <th className="text-center">S.no</th>
                <th>USERID</th>
                <th>NAME</th>
                <th>PHONE NO</th>
                <th>AMOUNT</th>
                <th>FEATURES</th>
                <th>STATUS</th>
                <th>DATE</th>
                <th>CalsCertificate</th>
              </tr>
            </thead>
            <tbody className="tbody-responsive-container">
              {currentEmails.map((email, index) => (
                <tr key={email.id} className="email-item">
                  <td className="text-center">{indexOfFirstEmail + index + 1}</td>
                  <td>{email.user_id}</td>
                  <td>{email.username}</td>
                  <td>{email.mobile_no}</td>
                  <td>{email.amount_in_paise / 100}</td> 
                  <td>{email.features}</td>
                  <td>
                    <p className="status">{email.transaction_status}</p>
                  </td>
                  <td>{email.created_at}</td>
                  <td>
                    <button
                      className="clas-certificate"
                      disabled={email.cals === 1}
                      onClick={() => {
                        setSelectedUser({ user_id: email.user_id });
                        setShowModal(true);
                      }}
                    >
                      Send
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          siblingCount={1}
          boundaryCount={1}
        />
      </div>

      {showModal && selectedUser && (
        <ConfirmationModal
          message="Are you sure you want to send the certificate?"
          onConfirm={() => calscerificate(selectedUser.user_id)}
          onCancel={() => setShowModal(false)}
          isSending={isSending}
        />
      )}
    </div>
  );
}
export default Paymentdetails;
