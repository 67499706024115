import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faListCheck, faDashboard, } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faHeart } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import user from '../../assets/Group.svg';
import "./sidebar.css";

const Sidebar = () => {
  const [visible, setVisible] = useState(false);
  const [menuIcon, setMenuIcon] = useState("jam--menu");
  const navigate = useNavigate();
  const location = useLocation(); // To get the current path

  const toggleMenu = () => {
    setVisible(!visible);
    setMenuIcon(menuIcon === "jam--menu" ? "gravity-xmark" : "jam--menu");
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    navigate('/');
  };

  return (
    <>
      <div className="top-nav">
        <img
          src={require("../../assets/framlogo.png")}
          alt="logo"
          className="top-logo"
        />
        <img
          src={require(`../../assets/${menuIcon}.png`)}
          alt="menu"
          className={`mobile-nav-btn p-2 ${menuIcon === "gravity-xmark" ? "gravity-xmark-icon" : ""
            }`}
          onClick={toggleMenu}
        />
      </div>

      <div className={visible ? "navbar open" : "navbar"}>
        <div className="sidebar__top">
          <div className="sidebar d-flex flex-column justify-content-between bg-white p-3 vh-100">
            <div>
              <ul className="nav link-primary flex-column p-2">
                <li
                  className={`navt-sidetext mt-2 mb-0 ${location.pathname === "/dashboard" ? "active" : ""
                    }`}
                >
                  <Link to="/dashboard" className="side  navt-sidetext ">
                    <FontAwesomeIcon icon={faDashboard} className="me-3 fs-6" />
                    Dashboard
                  </Link>
                </li>

                <li
                  className={`navt-sidetext mt-2 mb-0 ${location.pathname === "/Subscribtion" ? "active" : ""
                    }`}
                >
                  <Link to="/Subscribtion" className="  side navt-sidetext ">
                    <FontAwesomeIcon icon={faHeart} className="me-3 fs-6" />
                    Subscription
                  </Link>
                </li>
                <li 
                 className={`navt-sidetext mt-2 mb-0 ${location.pathname === "/user-report" ? "active" : ""
                 }`}
                >
                  <Link to="/user-report" className=" navt-sidetext side">
                    <FontAwesomeIcon icon={faEnvelope} className="me-3 fs-6" />
                    Inbox 
                  </Link>
                </li>            
                <li
                  className={`navt-sidetext mt-2 mb-0 ${location.pathname === "/user-list" ? "active" : ""
                    }`}
                >
                  <Link to="/user-list" className=" navt-sidetext side ">
                    <FontAwesomeIcon icon={faListCheck} className="me-3 fs-6" />
                    User Lists
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="nav link-primary flex-column settbottom p-0">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-auto">
                      <div className="profile">
                        <img src={user} alt="Profile" />
                      </div>
                    </div>
                  </div>
                </div>
                <li className="nav-item">
                  <button
                    onClick={handleLogout}
                    className="nav-link text-center logout-button"
                    data-tooltip-id="logoutTooltip"
                    data-tooltip-content="Logout"
                  >
                    <h6 className="text-dark">Logout</h6>
                  </button>
                  <Tooltip id="logoutTooltip" place="right" type="dark" effect="solid" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
