import React from "react";
import "./ConfirmationModal.css"; 
import PropTypes from "prop-types";
const ConfirmationModal = ({ message, onConfirm, onCancel,isSending  }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-box">
        <h3>Confirmation</h3>
        <p>{message}</p>
        <div className="modal-actions">
          <button className="cancel-btn" onClick={onCancel}>Cancel</button>
          <button className="confirm-btn" onClick={onConfirm} disabled={isSending}> {isSending ? "Sending..." : "Yes, Send"}</button>
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isSending : PropTypes.func.isRequired,
  };
export default ConfirmationModal;
