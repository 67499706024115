import React, { useState, useEffect } from 'react';
import "../style/SubscriptionPlans.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import config from "../config/config";

function Case() {
  const [searchTerm, setSearchTerm] = useState('');

  const [reports, setReports] = useState([]);
  const [selectedReports, setSelectedReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const reportsPerPage = 10;

  useEffect(() => {
    fetch(`${config.apiBaseUrl}/admin/user-reports`)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          alert(data.message);
        } else {
          setReports(data.reports);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching user reports:', error);
        setLoading(false);
      });
  }, []);

  const handleDelete = () => {
    selectedReports.forEach(reportId => {
      fetch(`${config.apiBaseUrl}/admin/user-reports/${reportId}`, {
        method: 'DELETE',
      })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            alert(data.message);
          }
        })
        .catch(error => {
          console.error('Error deleting user report:', error);
        });
    });
    setSelectedReports([]);
  };

  const handleSelectReport = (reportId) => {
    if (selectedReports.includes(reportId)) {
      setSelectedReports(selectedReports.filter(id => id !== reportId));
    } else {
      setSelectedReports([...selectedReports, reportId]);
    }
  };

  const filteredReports = reports.filter(report =>
    (report.username?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (report.report_details?.toLowerCase() || '').includes(searchTerm.toLowerCase())
  );

  const indexOfLastReport =  reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = filteredReports.slice(indexOfFirstReport, indexOfLastReport);



  if (loading) {
    return <div className="spinner">Loading...</div>;
  }

  return (
    <div className='Teams'>
      <h6 className='inboxhead'>Inbox</h6>
      <div className="email-list mt-3">
        <div className='row'>
          <div className='col-lg-9 col-md-8 col-12'>
            <div className="search-bar">
              <FontAwesomeIcon className="search-icon" icon={faSearch} />
              <input
                type="text"
                className='search-bar-input'
                placeholder="Search reports"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className='col-lg-1 col-md-1 mt-4 col-4 text-center'>
            <FontAwesomeIcon icon={faTrash} onClick={handleDelete} />
          </div>
          <div className='col-lg-2 col-md-3 col-8 mt-4'>
          
          </div>
        </div>
        <div className="table-responsive mt-4">
          <table className='table mt-3'>
            <colgroup>
              <col style={{ width: "50px" }} />
              <col style={{ width: "100px" }} />
              <col style={{ width: "100px" }} />
              <col style={{ width: "350px" }} />
              <col style={{ width: "100px" }} />
            </colgroup>
            <tbody>
              {currentReports.length > 0 ? (
                currentReports.map((report) => (
                  <tr key={report.report_id} className="email-item">
                    <td>
                      <input
                        type="checkbox"
                        className="form-check-input checboxt"
                        checked={selectedReports.includes(report.report_id)}
                        onChange={() => handleSelectReport(report.report_id)}
                      />
                    </td>
                    <td className="text-center">{report.username}</td>
                    <td className="text-center">{report.mobile_no}</td>
                    <td style={{ width: "200px" }}>{report.report_details}</td>
                    <td className='text-center'>{report.report_date}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Case;
